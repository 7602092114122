import data from "@/data/data-prepared.json";

const state = {
  data, // original data, should never change
  filteredData: data,
  hierarchyData: null,
  selectedLabel: null,
};

const mutations = {
  setHierarchyData(state, value) {
    state.hierarchyData = value;
  },
  setSelectedLabel(state, value) {
    if (state.selectedLabel === value) {
      // If value is already in selectedLabel, empty selectedLabel so that we see all articles
      state.selectedLabel = null;
    } else {
      state.selectedLabel = value;
    }
  },
  setFilteredData(state) {
    // Filter data by selected label. If no label is selected, use all data.
    const filteredData = state.selectedLabel
      ? state.data.filter((datum) => datum.labels.includes(state.selectedLabel))
      : state.data;

    state.filteredData = filteredData;
  },
  resetData(state) {
    state.filteredData = state.data;
  },
};

const getters = {
  data: (state) => state.data,
  filteredData: (state) => state.filteredData,
  hierarchyData: (state) => state.hierarchyData,
  selectedLabel: (state) => state.selectedLabel,
};

const actions = {
  setHierarchyData({ commit }, value) {
    commit("setHierarchyData", value);
  },
  setSelectedLabel({ commit }, value) {
    commit("setSelectedLabel", value);
    commit("setFilteredData");
  },
  resetData({ commit }) {
    commit("resetData");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
