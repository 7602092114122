/* eslint-disable */
require('./chevron')
require('./close')
require('./down-arrow')
require('./facebook')
require('./large-arrow')
require('./linkedin')
require('./long-arrow')
require('./medium-arrow')
require('./share')
require('./small-arrow')
require('./tema_lavoro')
require('./tema_minoranze')
require('./tema_uguaglianza')
require('./tema_unità')
require('./x')
