/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'down-arrow': {
    width: 8,
    height: 29,
    viewBox: '0 0 8 29',
    data: '<path pid="0" d="M3.646 28.354a.5.5 0 00.708 0l3.182-3.182a.5.5 0 10-.708-.707L4 27.293l-2.828-2.828a.5.5 0 10-.708.707l3.182 3.182zM3.5 0v28h1V0h-1z" _fill="#fff"/>'
  }
})
