const install = function (Vue) {
  Vue.mixin({
    computed: {
      $retina: () => window.devicePixelRatio > 1,
      $touchDevice: () => 'ontouchstart' in document.documentElement,
      $baseUrl: () => process.env.BASE_URL
    },
  });
};

export default install;
