/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chevron': {
    width: 30,
    height: 32,
    viewBox: '0 0 30 32',
    data: '<path pid="0" d="M11.461 23.753a.964.964 0 010-1.363l7.033-7.033-7.033-7.032a.964.964 0 011.364-1.364l7.714 7.714a.964.964 0 010 1.364l-7.714 7.714a.964.964 0 01-1.364 0z" _fill="#2B2B2B" _stroke="#2B2B2B" stroke-width=".857" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
