/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'share': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<path pid="0" d="M11.433 14.186l-3.5-1.909a3.333 3.333 0 110-4.554l3.5-1.908a3.333 3.333 0 11.799 1.463l-3.5 1.908a3.34 3.34 0 010 1.628l3.5 1.909a3.334 3.334 0 11-.8 1.462zM5.5 11.666a1.667 1.667 0 100-3.332 1.667 1.667 0 000 3.333zm9.166-5a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.334zm0 10a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.334z" _fill="#2B2B2B"/>'
  }
})
