import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  base: process.env.VUE_APP_PATH,
  routes: [
    {
      path: "/",
      redirect: "/it",
    },
    {
      path: "/:lang",
      alias: "/:lang/index.html",
      name: "home",
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/:lang/landing",
      alias: "/:lang/landing/index.html",
      name: "landing",
      component: () => import("@/views/Landing.vue"),
    },
    {
      path: "/:lang/viz",
      alias: "/:lang/viz/index.html",
      name: "constitution-page",
      component: () => import("@/views/ConstitutionPage.vue"),
    },
    {
      path: "/:lang/article",
      alias: "/:lang/article/index.html",
      name: "article",
      component: () => import("@/views/ArticlePage.vue"),
    },
    {
      path: "/:lang/themes",
      alias: "/:lang/themes/index.html",
      name: "themes",
      component: () => import("@/views/ThemesPage.vue"),
    },
    {
      path: "/:lang/theme",
      alias: "/:lang/theme/index.html",
      name: "theme",
      component: () => import("@/views/ThemePage.vue"),
    },
  ],
});
