import Vuex from "vuex";
import Vue from "vue";
import treemapViz from "./modules/treemapViz";
import data from "./modules/data";
import general from "./modules/general";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    treemapViz,
    data,
    general,
  },
});
