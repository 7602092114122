/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'large-arrow': {
    width: 28,
    height: 19,
    viewBox: '0 0 28 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.262 1.337a.563.563 0 00-.773 0 .51.51 0 000 .742l6.282 6.025h1.546l-7.055-6.767zm9.187 7.73a1.42 1.42 0 00-.355-.543L18.932.694a1.54 1.54 0 00-2.113 0 1.392 1.392 0 000 2.027l5.612 5.383H1.55c-.825 0-1.494.642-1.494 1.434 0 .791.67 1.433 1.494 1.433h20.88l-5.612 5.383a1.392 1.392 0 000 2.027c.584.56 1.53.56 2.113 0l8.162-7.83a1.421 1.421 0 00.43-.872 1.384 1.384 0 00-.075-.612zm-2.133 1.904h-1.545l-6.282 6.025a.51.51 0 000 .742c.213.205.56.205.773 0l7.055-6.767zm-.598-.91h1.32a.536.536 0 00.546-.523.536.536 0 00-.547-.525h-1.319l.212.203a.445.445 0 010 .643l-.212.203zm-1.34-1.048H1.551a.536.536 0 00-.546.525c0 .29.245.524.546.524h21.827l.547-.524-.547-.525z" _fill="#2B2B2B"/>'
  }
})
