const state = {
  modalState: false,
  hoverData: null,
  hoverX: 0,
  hoverY: 0,
};

const mutations = {
  setModalState(state, value) {
    state.modalState = value;
  },
  setHoverData(state, value) {
    state.hoverData = value;
  },
  setHoverX(state, value) {
    state.hoverX = value;
  },
  setHoverY(state, value) {
    state.hoverY = value;
  },
};

const getters = {
  getModalState: (state) => state.modalState,
  getHoverData: (state) => state.hoverData,
  getHoverX: (state) => state.hoverX,
  getHoverY: (state) => state.hoverY,
};

const actions = {
  setModalState({ commit }, value) {
    commit("setModalState", value);
  },
  setHoverData({ commit }, value) {
    commit("setHoverData", value);
  },
  setHoverX({ commit }, value) {
    commit("setHoverX", value);
  },
  setHoverY({ commit }, value) {
    commit("setHoverY", value);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
