<template>
  <main
    class="mobile-disclaimer"
    :style="{
      backgroundImage: `url(${$baseUrl}images/mobile-disclaimer.svg)`,
    }"
  >
  </main>
</template>

<script>
export default {
  name: "MobileDisclaimer",
};
</script>

<style lang="scss" scoped>
.mobile-disclaimer {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  padding: toRem(78) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-dark;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  &__title {
    width: 80%;
  }

  &__disclaimer {
    width: 100%;
  }
}
</style>
