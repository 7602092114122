<template>
  <div id="app" :class="[$tvaMq]" :version="version">
    <transition name="fadepage">
      <router-view
        v-if="!isSmallScreen"
        :key="$route.query.theme || $route.query.articleId"
      ></router-view>
      <!-- <LandscapeDisclaimer v-if="!showApp" /> -->
      <MobileDisclaimer v-if="isSmallScreen" />
    </transition>
  </div>
</template>

<script>
import { version } from "@/../package.json";

import { enrichDataFunc } from "@/utilities/functions/enrichData";
import { mapActions, mapGetters } from "vuex";
// import LandscapeDisclaimer from "./views/LandscapeDisclaimer.vue";
import MobileDisclaimer from "./views/MobileDisclaimer.vue";

export default {
  components: {
    // LandscapeDisclaimer,
    MobileDisclaimer,
  },
  name: "App",

  data() {
    return {
      version,
      showApp: true,
    };
  },
  beforeCreate() {
    let lang;
    if (this.$route.params.lang) {
      lang = this.$route.params.lang;
    } else {
      lang = "it";
      this.$route.params.lang = "it";
    }
    this.$i18n.locale = lang.toLowerCase();

    // Set html language attribute
    document.querySelector("html").setAttribute("lang", lang.toLowerCase());
    // this.$router.push({ name: "landing" });
  },
  created() {
    // set data
    const { enrichedData, totalDepth } = enrichDataFunc(this.data);
    this.setHierarchyData(enrichedData);
    this.setNormalizeViz({ index: totalDepth - 1 });
  },
  mounted() {
    this.setVHProperty();

    window.addEventListener("resize", this.setVHProperty);
    window.addEventListener("orientationchange", this.setVHProperty);
    window.addEventListener("orientationchange", this.showAppOrDisclaimer);
  },
  watch: {
    $route(to) {
      if (this.$tvaMq === "desktop" && this.$route.name === "home") {
        this.$router.push({ name: "landing" });
      }
      if (this.$route.params.lang) {
        this.$i18n.locale = to.params.lang.toLowerCase();
      }
    },
  },
  computed: {
    isSmallScreen() {
      return this.$tvaMq === "mobile";
    },
    ...mapGetters(["selectedLabel", "data"]),
  },
  methods: {
    setVHProperty() {
      // Control for having always fixed portrait mode
      let vh = window.innerHeight * 0.01;
      // let vh = window.matchMedia("(orientation: portrait)").matches
      //   ? window.innerHeight * 0.01
      //   : window.innerWidth * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    showAppOrDisclaimer() {
      // Really really weird but this works exactly the opposite of how it should work
      // maybe there is a delay so that this value is not updated yet on orientation change...?

      this.showApp =
        (!window.matchMedia("(display-mode: standalone)").matches &&
          window.matchMedia("(orientation: landscape)").matches) ||
        (window.matchMedia("(display-mode: standalone)").matches &&
          window.matchMedia("(orientation: portrait)").matches);
    },

    ...mapActions(["setHierarchyData", "setNormalizeViz"]),
  },
};
</script>

<style lang="scss">
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 0;
}
button {
  background-color: transparent;
  border: none;

  &:hover:not(:disabled) {
    cursor: pointer;
  }
}
a {
  text-decoration: none;
}

sup,
sub {
  font-size: 75%;
  display: inline-block;
}

sup {
  transform: translateY(-25%);
}

sub {
  transform: translateY(15%);
}

strong {
  font-weight: 800;
}

em {
  font-style: italic;
}

// We need this because the dimensions according to the design were x2 too big
// (this was because the design was made according to the physical amount of pixels
// in the ipad device, while the device to pixel ratio is such that every virtual
// pixel occupies more or less 2 physical pixels)
html {
  @media only screen and (min-height: 600px) {
    --fontsize-factor: 0.85;
    font-size: calc(100% * var(--fontsize-factor));
  }

  @media only screen and (min-height: 670px) {
    --fontsize-factor: 0.9;
    font-size: calc(100% * var(--fontsize-factor));
  }

  @media only screen and (min-height: 700px) {
    --fontsize-factor: 0.95;
    font-size: calc(100% * var(--fontsize-factor));
  }

  @media only screen and (min-height: 750px) {
    --fontsize-factor: 1;
    font-size: calc(100% * var(--fontsize-factor));
  }

  @media only screen and (max-width: 900px) {
    font-size: 50%;
  }

  // font-size: 50%;
  // .tablet {
  // }

  // & > body {
  //   & > .tablet {
  //   }
  // }
  // Unfortunately Safari can't make use of the Screen Orientation API...
  // So we need to fix the screen orientation in a hacky way
  // @media screen and (orientation: landscape) {
  //   transform: rotate(90deg);
  //   transform-origin: right top;
  //   width: calc(var(--vh, 1vh) * 100);
  //   height: 100vw;
  //   overflow-x: hidden;
  //   position: absolute;
  //   top: 100%;
  //   right: 0;
  // }
}

*,
::before,
::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "Spectral", serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tablet {
  font-size: 50%;
}
</style>
