/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.408 1.294a.58.58 0 01.82 0L14.59 13.658a.58.58 0 01-.82.82L1.409 2.113a.58.58 0 010-.82z" _fill="#2B2B2B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.591 1.294a.58.58 0 010 .82L2.228 14.478a.58.58 0 01-.82-.82L13.772 1.294a.58.58 0 01.82 0z" _fill="#2B2B2B"/><path pid="2" clip-rule="evenodd" d="M1.408 1.294a.58.58 0 01.82 0L14.59 13.658a.58.58 0 01-.82.82L1.409 2.113a.58.58 0 010-.82z" _stroke="#2B2B2B" stroke-width="1.159" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" clip-rule="evenodd" d="M14.591 1.294a.58.58 0 010 .82L2.228 14.478a.58.58 0 01-.82-.82L13.772 1.294a.58.58 0 01.82 0z" _stroke="#2B2B2B" stroke-width="1.159" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
