import { forEach, groupBy, filter } from "lodash";
import * as d3 from "d3-hierarchy";

/**
 *
 * @param {*} rawData
 * @param {*} keys
 * @param {*} itemPerLevel
 * @returns
 */
function stratifyFunction(rawData, keys, itemPerLevel, counter) {
  let res = { name: "flare", children: [] };

  const stratify = (data, keys, itemPerLevel) => {
    const groupFunction = (data, keys, index = 0) => {
      let res = [];

      let groups = groupBy(data, (d) => d[keys[index]]);
      forEach(groups, (group, groupKey) => {
        // If the group is not from an article, set namePrefix
        // (= the group number, e.g. Titolo I, and will be the same for every group member)
        const namePrefix =
          group.length > 1 ? group[0][keys[index] + "Nr"] : undefined;

        let item = { name: groupKey, namePrefix };
        switch (index) {
          case 0:
            item.id = counter[0];
            break;
          case 1:
            item.id = counter[0] + "-" + counter[1];
            break;
          case 2:
            item.id = counter[0] + "-" + counter[1] + "-" + counter[2];
            break;
          case 3:
            item.id =
              counter[0] +
              "-" +
              counter[1] +
              "-" +
              counter[2] +
              "-" +
              counter[3];
            break;
        }
        if (keys[index + 1]) {
          let counterKeys = Object.keys(counter);
          counterKeys.map((ck) => {
            ck > index ? (counter[ck] = 0) : null;
          });
          item.children = groupFunction(group, keys, index + 1);
          item.articles = group;
        } else {
          item.value = group;
        }
        item.levelAmount = itemPerLevel[index];
        res.push(item);
        counter[index] = counter[index] + 1;
      });

      return res;
    };
    return groupFunction(data, keys);
  };

  res.children = stratify(rawData, keys, itemPerLevel);
  return res;
}
/**
 *
 * @param {*} data
 * @param {*} keys
 * @returns
 */
const countItemPerLevel = (data, keys) => {
  let res = {};
  const groupFunction = (data, keys, index = 0) => {
    let groups = groupBy(data, (d) => d[keys[index]]);
    forEach(groups, (group) => {
      if (keys[index + 1]) {
        groupFunction(group, keys, index + 1);
      }
      res[index] = res[index] ? res[index] + 1 : (res[index] = 1);
    });
  };
  groupFunction(data, keys);
  return res;
};

/**
 *
 * @param {*} data
 * @param {*} itemPerLevel
 * @returns
 */
const enrichData = (data, itemPerLevel) => {
  let hierarchyData = d3.hierarchy(data);

  let tmpNormalized = {};

  let tmpPercentageScaled = {};
  hierarchyData.each((d) => {
    // initialize
    d.data.weight = {};
    d.data.progress = {};

    // WEIGHT

    // add weight normalized
    d.data.weight.normalized = d.data.levelAmount
      ? 100 / d.data.levelAmount
      : 100;
    // add weight scaled
    let descendants = d.descendants();
    forEach(itemPerLevel, (o, k) => {
      d.data.weight[`scaled-${k}`] =
        (100 / o) * filter(descendants, (p) => p.depth == +k + 1).length;
    });

    // PROGRESS

    // add progress normalized
    if (!tmpNormalized[d.depth]) tmpNormalized[d.depth] = 0;
    d.data.progress.normalized = tmpNormalized[d.depth];
    tmpNormalized[d.depth] = tmpNormalized[d.depth] + d.data.weight.normalized;

    // add progress scaled
    forEach(itemPerLevel, (o, k) => {
      if (!tmpPercentageScaled[`${d.depth}-${k}`])
        tmpPercentageScaled[`${d.depth}-${k}`] = 0;
      d.data.progress[`scaled-${k}`] = tmpPercentageScaled[`${d.depth}-${k}`];
      tmpPercentageScaled[`${d.depth}-${k}`] =
        tmpPercentageScaled[`${d.depth}-${k}`] + d.data.weight[`scaled-${k}`];
    });
  });
  return hierarchyData;
};
/**
 *
 * @param {*} obj
 * @returns
 */
function setValuesToZero(obj) {
  for (var key in obj) {
    obj[key] = 0;
  }
  return obj;
}

export function enrichDataFunc(data) {
  const keys = ["part", "title", "n_articolo"];
  const itemPerLevel = countItemPerLevel(data, keys);
  const itemCounter = setValuesToZero({ ...itemPerLevel });
  const stratifiedData = stratifyFunction(
    data,
    keys,
    itemPerLevel,
    itemCounter
  );
  const enrichedData = enrichData(stratifiedData, itemPerLevel);

  return { enrichedData, totalDepth: keys.length };
}
