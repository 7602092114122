import Vue from "vue";
import App from "./App.vue";

// Plugins
import tvaMq from "./plugins/tvaMq";
Vue.use(tvaMq, {
  mobile: 600,
  tablet: 900,
  desktop: Infinity,
});

// Svg icon
import VueSVGIcon from "vue-svgicon";
import "vue-svgicon/dist/polyfill";
import "@/compiled-icons";
Vue.use(VueSVGIcon);

import globalVariables from "./plugins/globalVariables";
Vue.use(globalVariables);

import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;
Vue.prototype.$baseUrl = process.env.BASE_URL;
Vue.prototype.$baseFontSize = 16;
Vue.prototype.$isProduction = process.env.NODE_ENV === "production";

import router from "./utilities/router";
import store from "./utilities/store";
import i18n from "./utilities/language";
import "./registerServiceWorker";

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
