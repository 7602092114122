const install = function (Vue, options) {
  Vue.mixin({
    data() {
      return {
        device: "",
        breakpoints: {
          mobile: 420,
          tablet: 1024,
          desktop: Infinity,
        },
      };
    },
    created() {
      if (options) this.breakpoints = options;

      this.device = this.checkDevice();

      window.addEventListener("resize", () => {
        this.device = this.checkDevice();
      });
    },
    methods: {
      checkDevice() {
        return window.innerWidth <= this.breakpoints.mobile
          ? "mobile"
          : window.innerWidth <= this.breakpoints.tablet
          ? "tablet"
          : "desktop";
      },
    },
    computed: {
      $tvaMq() {
        return this.device;
      },
    },
  });
};

export default install;
