/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'small-arrow': {
    width: 10,
    height: 19,
    viewBox: '0 0 10 19',
    data: '<path pid="0" d="M.458 17.75a.964.964 0 010-1.363l7.033-7.033L.458 2.322A.964.964 0 111.822.958l7.714 7.714a.964.964 0 010 1.364L1.822 17.75a.964.964 0 01-1.364 0z" _fill="#63A2EE"/>'
  }
})
