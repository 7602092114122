const state = {
  vizElSelected: null,
  nextVizEl: null,
  prevVizEl: null,
  filterViz: "versi",
  normalizeViz: null,
  isTransitioning: false,
};

const mutations = {
  setVizElSelected(state, value) {
    state.vizElSelected = value;
  },
  setNextVizEl(state, value) {
    state.nextVizEl = value;
  },
  setPrevVizEl(state, value) {
    state.prevVizEl = value;
  },
  setFilterViz(state, value) {
    state.filterViz = value;
  },
  setNormalizeViz(state, value) {
    state.normalizeViz = value;
  },
  setIsTransitioning(state, value) {
    state.isTransitioning = value;
  },
};

const getters = {
  getVizElSelected: (state) => state.vizElSelected,
  getNextVizElement: (state) => state.nextVizEl,
  getPrevVizElement: (state) => state.prevVizEl,
  getFilterViz: (state) => state.filterViz,
  getNormalizeViz: (state) => state.normalizeViz,
  getIsTransitioning: (state) => state.isTransitioning,
  getElementsWidthDesktop: (state) =>
    retrieveElementsWidth(state.vizElSelected?.depth, true),
  getElementsWidthSmallScreen: (state) =>
    retrieveElementsWidth(state.vizElSelected?.depth, false),
};

const actions = {
  setVizElSelected({ commit }, value) {
    commit("setVizElSelected", value);
  },
  setNextVizEl({ commit }, value) {
    commit("setNextVizEl", value);
  },
  setPrevVizEl({ commit }, value) {
    commit("setPrevVizEl", value);
  },
  resetViz({ commit }) {
    commit("setVizElSelected", null);
    commit("setPrevVizEl", null);
    commit("setNextVizEl", null);
  },
  setFilterViz({ commit }, value) {
    commit("setFilterViz", value);
  },
  setNormalizeViz({ commit }, value) {
    commit("setNormalizeViz", value);
  },
  setIsTransitioning({ commit }, value) {
    commit("setIsTransitioning", value);
  },
};

const elementsWidthDesktop = {
  1: [0.36, 0.435, 0.535],
  2: [0.36, 0.435, 0.975],
  3: [0.36, 0.44, 0.975],
  default: [0.31, 0.31, 0.38],
};

const elementsWidthSmallScreen = {
  1: [0.4, 0.38, 0.6],
  2: [0.4, 0.38, 0.98],
  3: [0.4, 0.38, 0.98],
  default: [0.35, 0.1, 0.55],
};

function retrieveElementsWidth(depth, forDesktop) {
  const widthOptions = forDesktop
    ? elementsWidthDesktop
    : elementsWidthSmallScreen;

  return widthOptions[depth] || widthOptions.default;
}

export default {
  state,
  getters,
  actions,
  mutations,
};
