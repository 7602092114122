/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkedin': {
    width: 23,
    height: 22,
    viewBox: '0 0 23 22',
    data: '<path pid="0" d="M5.333 3.168A2.167 2.167 0 111 3.166a2.167 2.167 0 014.333.002zm.065 3.77H1.065V20.5h4.333V6.938zm6.847 0H7.933V20.5h4.269v-7.117c0-3.965 5.167-4.334 5.167 0V20.5h4.28v-8.59c0-6.685-7.649-6.436-9.447-3.153l.043-1.82z" _fill="#333"/>'
  }
})
