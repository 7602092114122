/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'medium-arrow': {
    width: 33,
    height: 28,
    viewBox: '0 0 33 28',
    data: '<path pid="0" d="M7.344 12.69c0-.374.323-.677.722-.677h17.333c.4 0 .723.303.723.678 0 .374-.324.677-.723.677H8.066c-.399 0-.722-.303-.722-.677z" _fill="#2B2B2B"/><path pid="1" d="M19.11 6.79a.756.756 0 011.022 0l5.778 5.421a.649.649 0 010 .959l-5.778 5.422a.756.756 0 01-1.021 0 .649.649 0 010-.959l5.267-4.942-5.267-4.943a.649.649 0 010-.959z" _fill="#2B2B2B"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M19.895 7.012a.405.405 0 00-.548 0 .347.347 0 000 .513l4.447 4.173h1.094l-4.993-4.686zm6.503 5.352a.982.982 0 00-.251-.375l-5.778-5.422a1.107 1.107 0 00-1.496 0 .95.95 0 000 1.403l3.973 3.728H8.066c-.585 0-1.058.444-1.058.992 0 .549.473.993 1.057.993h14.78l-3.972 3.728a.95.95 0 000 1.403 1.107 1.107 0 001.496 0l5.778-5.422a.983.983 0 00.304-.603.933.933 0 00-.053-.425zm-1.51 1.319h-1.094l-4.447 4.173a.348.348 0 000 .513.405.405 0 00.548 0l4.993-4.686zm-.423-.63h.934c.213 0 .387-.162.387-.363 0-.2-.174-.363-.387-.363h-.934l.15.14a.305.305 0 01.098.223.305.305 0 01-.099.223l-.15.14zm-.949-.726H8.066c-.214 0-.388.163-.388.363 0 .2.174.363.387.363h15.451l.387-.363-.387-.363z" _fill="#2B2B2B"/>'
  }
})
