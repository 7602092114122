/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'long-arrow': {
    width: 207,
    height: 8,
    viewBox: '0 0 207 8',
    data: '<path pid="0" d="M.646 3.503a.5.5 0 000 .707l3.182 3.182a.5.5 0 10.708-.707L1.707 3.857l2.829-2.828A.5.5 0 103.828.32L.646 3.503zM1 4.357h206v-1H1v1z" _fill="#63A2EE"/>'
  }
})
